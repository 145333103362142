import { Link } from "gatsby";
import React, { useState } from "react";
import { ExternalLink } from "../../elements/ExternalLink";
import { Flex } from "../../elements/Flex";
import { Text } from "../../elements/Text";
import { links, shopLinks } from "../Navbar/links";

const MobileMenu = ({ isOpen }) => {
  const [showShopLinks, setShowShopLinks] = useState(false);

  const contentOpen = {
    overflow: "visible",
    height: "auto",
  };

  const contentClosed = {
    overflow: "hidden",
    height: 0,
  };

  const contentProps = isOpen ? contentOpen : contentClosed;
  const textStyle = {
    cursorPointer: true,
    color: "grey",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    p: 3,
    textTransform: "capitalize",
  };

  return (
    <Flex width="100%" {...contentProps} animate>
      <Flex flexDirection="column" width="100%">
        <ul>
          {(showShopLinks ? shopLinks : links).map(({ name, path, href }, index) =>
            href ? (
              <li key={index}>
                <ExternalLink textDecoration="none" href={href} target="_blank" rel="noopener noreferrer">
                  <Text {...textStyle}>{name}</Text>
                </ExternalLink>
              </li>
            ) : name === "Shop" || name === "Back" ? (
              <li key={index} aria-hidden="true">
                <Text onClick={() => setShowShopLinks(!showShopLinks)} {...textStyle}>
                  {name}
                </Text>
              </li>
            ) : (
              <li key={index} aria-hidden="true">
                <Link to={path}>
                  <Text {...textStyle}>{name}</Text>
                </Link>
              </li>
            )
          )}
        </ul>
      </Flex>
    </Flex>
  );
};

export default MobileMenu;
