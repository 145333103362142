import styled, { css } from "styled-components";
import { space, layout, color, flexbox, background, border, position, typography, shadow } from "styled-system";

export const Flex = styled.div`
  transition: 0.5s all ease;
  display: flex;
  ${space};
  ${layout};
  ${color};
  ${flexbox};
  ${background};
  ${border};
  ${position};
  ${typography};
  ${shadow}
  font-family: ${(props) => props.fontFamily};
  z-index: ${(props) => props.zIndex};

  transition: ${(props) => props.noTransition && "none"};
  ${({ cursorPointer }) =>
    cursorPointer &&
    css`
      cursor: pointer;
    `};

  @media screen and (min-width: 768px) {
    transform: ${(props) =>
      props.transformSubCard &&
      css`
      scale(1.07)
  `};
  }
`;
