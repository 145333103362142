export const links = [
  {
    name: "Home",
    path: "http://leanwithlilly.com/",
  },
  {
    name: "App",
    path: "https://leanwithlilly.com/pages/app",
  },
  {
    name: "Shop",
    path: "https://leanwithlilly.com/pages/shop",
  },
  {
    name: "Classes",
    path: "/app/classes",
    requiresSubscription: true,
  },
  // {
  //   name: "Challenge",
  //   path: "https://leanwithlilly.com/pages/challenge",
  // },
  {
    name: "Recipes",
    path: "https://leanwithlilly.com/blogs/healthy-recipes",
  },
  {
    name: "Students",
    path: "https://leanwithlilly.com/pages/student",
  },
  {
    name: "Blog",
    path: "https://leanwithlilly.com/blogs/blog",
  },
  {
    name: "FAQ & Support",
    href: "https://support.leanwithlilly.com/hc/en-us",
  },
];

export const aboutLinks = [{ name: "Back" }, { name: "App", path: "/lean-app" }];

export const shopLinks = [
  {
    name: "Back",
  },
  {
    name: "Home",
    path: "https://leanwithlilly.com/pages/shop",
  },
  {
    name: "All Products",
    path: "https://leanwithlilly.com/collections/all-products",
  },
  {
    name: "Nutrition",
    path: "https://leanwithlilly.com/collections/nutrition",
  },
  {
    name: "Fitness",
    path: "https://leanwithlilly.com/collections/fitness",
  },
  {
    name: "Bundles",
    path: "https://leanwithlilly.com/a/bundles/",
  },

  {
    name: "Accessories",
    path: "https://leanwithlilly.com/collections/accessories",
  },
  // {
  //   name: "Cookbook",
  //   path: "/products/the-anti-inflammatory-cook-book",
  // },
  // {
  //   name: "LEAN Guides",
  //   path: "/collections/frontpage",
  // },
  // {
  //   name: "Compare Guides",
  //   path: "/pages/compare-8-week-lean-guides",
  // },
];
