import { Auth } from "aws-amplify";
import { vendorUUID } from "./constants";

const isBrowser = typeof window !== `undefined`;

const getUserName = (email) => `${email.toLowerCase()}#${vendorUUID}`;

export const getUser = () => {
  if (isBrowser) {
    try {
      return Auth.currentAuthenticatedUser();
    } catch (e) {
      console.log("e", e);
    }
  }
};

export const getToken = () => isBrowser && Auth.currentSession();

export const register = async ({ firstName, lastName, email, password }) => {
  const result = await Auth.signUp({
    username: getUserName(email),
    password: password,
    attributes: {
      email: email,
      given_name: firstName,
      family_name: lastName,
    },
  });

  if (result) {
    return await login({ email: email, password: password });
  }
};

export const login = async ({ email, password }) => {
  const result = await Auth.signIn(getUserName(email), password);
  return result && result.signInUserSession;
};

export const logout = async () => {
  if (isBrowser) await Auth.signOut();
};
