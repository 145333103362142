import styled from "styled-components";
import { background, border, color, layout, shadow, space, typography, variant } from "styled-system";

export const Button = styled.button`
  width: fit-content;
  max-height: fit-content;
  ${typography};
  ${layout};
  ${space};
  ${color};
  ${background};
  ${border};
  ${shadow};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: ${(props) => props.textTransform};

  :disabled {
    opacity: 0.75;
    cursor: auto;
  }

  ${variant({
    variants: {
      primary: {
        py: 3,
        px: 4,
        bg: "grey",
        border: "none",
        borderRadius: "30px",
        color: "white",
        fontWeight: 500,
        letterSpacing: "2px",
        opacity: 1,
        "&:hover": {
          opacity: 0.75,
        },
        transition: "0.5s all ease",
      },
      secondary: {
        py: 2,
        px: 3,
        bg: "green",
        border: "none",
        color: "white",
        letterSpacing: "2px",
        fontWeight: 500,
        fontSize: "16px",
        opacity: 1,
        "&:hover": {
          opacity: 0.75,
        },
      },
      secondaryOutline: {
        py: 2,
        px: 3,
        bg: "transparent",
        border: "1px solid #33ca55",
        color: "green",
        letterSpacing: "2px",
        fontWeight: 500,
        fontSize: "16px",
        opacity: 1,
        "&:hover": {
          opacity: 0.75,
        },
      },
      icon: {
        p: 1,
        bg: "transparent",
        border: "none",
      },
      faq: {
        mx: "1px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        width: "100%",
        p: 2,
        px: 3,
        border: "1px solid lightGrey",
        borderBottom: "none",
        bg: "white",
        color: "teal",
        fontSize: 15,
        fontWeight: "600",
      },
      faqActive: {
        mx: "1px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        width: "100%",
        p: 2,
        px: 3,
        border: "1px solid lightGrey",
        borderBottom: "none",
        bg: "lightGrey",
        color: "grey",
        fontSize: 15,
        fontWeight: "600",
      },
      social: {
        py: 1,
        px: 2,
        border: "1px solid lightGrey",
        bg: "white",
        letterSpacing: 2,
        color: "black",
        fontWeight: 600,
      },
      storeSelect: {
        py: 3,
        px: 2,
        border: "2px solid #cfcfcf",
        bg: "transparent",
        letterSpacing: 2,
        color: "#cfcfcf",
        fontWeight: 600,
      },
      storeSelectActive: {
        py: 3,
        px: 2,
        border: "2px solid #2db3b6",
        bg: "transparent",
        letterSpacing: 2,
        color: "#2db3b6",
        fontWeight: 600,
      },
      darkTeal: {
        bg: "darkTeal",
        border: "none",
        py: "0.85rem",
        px: 3,
        color: "white",
        fontWeight: "600",
        fontSize: 14,
        /* borderRadius: "5px", */
        transition: "0.5s all ease",

        "&:hover": {
          bg: "#38d3d6",
        },
      },
      darkPink: {
        bg: "darkPink",
        border: "none",
        py: "0.85rem",
        px: 3,
        color: "white",
        fontWeight: "600",
        fontSize: 14,
        borderRadius: "5px",

        "&:hover": {
          opacity: 0.75,
        },
      },
      lightPink: {
        bg: "pink",
        border: "none",
        py: "0.85rem",
        px: 3,
        color: "white",
        fontWeight: "600",
        fontSize: 14,
        borderRadius: "5px",

        "&:hover": {
          opacity: 0.75,
        },
      },
      rounded: {
        fontWeight: 600,
        px: 3,
        py: 2,
        fontSize: 15,
        borderRadius: "30px",
        textTransform: "uppercase",
        border: "none",
      },
    },
  })}
`;
