import { Link } from "gatsby";
import React from "react";
import { Box } from "../../elements/Box";
import { Flex } from "../../elements/Flex";
import { Text } from "../../elements/Text";
import DownloadButtons from "../DownloadButtons";
import { ExternalLink } from "../../elements/ExternalLink";
import Genflow from "../../images/Genflow.png";
import { Image } from "../../elements/Image";
import SocialButtons from "../SocialButtons";
import {footerLinks} from "./links"

const Footer = () => {
  return (
    <Flex flexDirection={["column", "row", "row"]} justifyContent="space-between" p={5}>
      <Box fontSize={14} mb={3}>
        <Text fontWeight="600" textTransform="uppercase" mb={2}>
          Information
        </Text>
        <Link to={footerLinks.information.shippingInformation}>
          <Text color="black" mb={2}>
            Shipping Information
          </Text>
        </Link>

        <ExternalLink
          textDecoration="none"
          color="black"
          href={footerLinks.information.faq}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text mb={2}>FAQ &amp; Support</Text>
        </ExternalLink>

        <Link to={footerLinks.information.refund}>
          <Text color="black" mb={2}>
            Refund Policy
          </Text>
        </Link>

        {/* <Link to="/pages/contact">
              <Text>Brand Collaborations</Text>
            </Link> */}
      </Box>

      <Box fontSize={14} mb={3}>
        <Text color="black" fontWeight="600" textTransform="uppercase" mb={2}>
          Legal
        </Text>

        <Link to={footerLinks.legal.consumerGoods}>
          <Text color="black" mb={2}>
            Consumer Goods T&amp;Cs
          </Text>
        </Link>

        <Link to={footerLinks.legal.acceptableUsePolicy}>
          <Text color="black" mb={2}>
            Acceptable Use Policy
          </Text>
        </Link>

        <Link to={footerLinks.legal.privacyPolicy}>
          <Text color="black" mb={2}>
            Privacy Policy
          </Text>
        </Link>

        <Link to={footerLinks.legal.terms}>
          <Text color="black" mb={2}>
            Website T&amp;Cs
          </Text>
        </Link>
      </Box>
      <Box mb={3}>
        <DownloadButtons noLink />
      </Box>
      <Box fontSize={14} mb={3}>
        <Text fontWeight="600" textTransform="uppercase" mb={3} textAlign="center">
          Stay Connected
        </Text>
        <SocialButtons />
        <Flex mt={4}>
          <ExternalLink href="https://genflow.com/" target="_blank" rel="noopener noreferrer" mx="auto">
            <Image mx="auto" src={Genflow} alt="Genflow" width="100px" />
          </ExternalLink>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
