export const metaData = [
  {
    path: "/",
    title: "Home",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/pages/about-me",
    title: "About",
    description:
      "I’m Lilly Sabri, founder of Lean with Lilly. I’ve been a Chartered Physiotherapist since 2010, APPI Pilates instructor, Fitness, Heath and Lifestyle Influencer.  Over the years, I’ve educated and empowered hundreds of thousands of people around the world to live a healthier lifestyle in a way that encourages increased ",
  },
  {
    path: "/pages/faq",
    title: "FAQ",
    description:
      "FAQ &amp; Support Orders and Payments Shipping Guides and Cookbook Resistance Bands Refund and Returns What types of payments are accepted? Credit and Debit Cards (Visa, Mastercard, Amex, Maestro), PayPal, Apple Pay, Google Pay and Shop Pay. What happens if I want to change or cancel my order once purchased? Dispatch t",
  },
  {
    path: "/policies/shipping-policy",
    title: "Shipping policy",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/policies/refund-policy",
    title: "Refund policy",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/pages/contact",
    title: "Contact Lily Sabri",
    description:
      "Get in touch with us! Whether you have a question, a business enquiry or you want to let us know how you get on with Lilly's recipes and workouts, please complete the form below and we'll get back to you as soon as possible. For order Inquiries, please include your order number (e.g. #LWL1234).",
  },
  {
    path: "/policies/terms-of-service",
    title: "Consumer Goods T&Cs",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/policies/privacy-policy",
    title: "Privacy policy",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/cart",
    title: "Your Shopping Cart",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/collections/resistance-bands",
    title: "Resistance Bands",
    description:
      "ADJUSTABLE RESISTANCE BANDS Sold out Sold out HIIT BANDS Sold out Sold out 'These bands are AMAZING, you'll get a new workout, I used to use plastic ones, they left me with a rash, pinched my skin and never seemed to give me good resistance then I got the LEAN bands and OMG the burn, theyr great quality amazing price I",
    extra: [
      {
        property: "og:type",
        content: "product.group",
      },
    ],
  },
  {
    path: "/pages/bundles",
    title: "Bundles",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/collections/frontpage",
    title: "LEAN Guides",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
    extra: [
      {
        property: "og:type",
        content: "product.group",
      },
    ],
  },
  {
    path: "/pages/compare-8-week-lean-guides",
    title: "Compare Lean Guides",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/products/lean-skipping-rope",
    title: "LEAN Skipping Rope",
    description: "",
  },
  {
    path: "/products/adjustable-resistance-band",
    title: "Adjustable Resistance Band",
    description:
      "Fabric band perfect for different intensities of your workouts - simply adjust the band to increase or decrease the resistance. Adjustable buckle Moveable strap Fabric LEAN logo Stretchable fabric material Non-slip design Perfect for: all resistance, bodyweight and activation workouts involving exercises like Squats, L",
    extra: [
      {
        property: "og:type",
        content: "product.group",
      },
      {
        property: "og:description",
        content:
          "Fabric band perfect for different intensities of your workouts - simply adjust the band to increase or decrease the resistance. Adjustable buckle Moveable strap Fabric LEAN logo Stretchable fabric material Non-slip design Perfect for: all resistance, bodyweight and activation workouts involving exercises like Squats, L",
      },
      {
        property: "og:price:amount",
        content: "19.00",
      },
      {
        property: "og:price:curency",
        content: "GBP",
      },
    ],
  },
  {
    path: "/products/lean-long-band-set",
    title: "LEAN Long Band Set",
    description:
      "The brand new Long LEAN Bands comes in a set of three to accommodate for the different styles of workouts and the different areas of the body. These bands can replace weights at home or make your gym workouts even more intense. Different Strengths: Aqua: Medium – For core and upper body exercises Black: Strong – Upper ",
    extra: [
      {
        property: "og:type",
        content: "product.group",
      },
      {
        property: "og:description",
        content:
          "The brand new Long LEAN Bands comes in a set of three to accommodate for the different styles of workouts and the different areas of the body. These bands can replace weights at home or make your gym workouts even more intense. Different Strengths: Aqua: Medium – For core and upper body exercises Black: Strong – Upper ",
      },
      {
        property: "og:price:amount",
        content: "35.00",
      },
      {
        property: "og:price:curency",
        content: "GBP",
      },
    ],
  },
  {
    path: "/products/lean-long-band-set",
    title: "LEAN Long Band Set",
    description:
      "The brand new Long LEAN Bands comes in a set of three to accommodate for the different styles of workouts and the different areas of the body. These bands can replace weights at home or make your gym workouts even more intense. Different Strengths: Aqua: Medium – For core and upper body exercises Black: Strong – Upper ",
    extra: [
      {
        property: "og:type",
        content: "product.group",
      },
      {
        property: "og:description",
        content:
          "The brand new Long LEAN Bands comes in a set of three to accommodate for the different styles of workouts and the different areas of the body. These bands can replace weights at home or make your gym workouts even more intense. Different Strengths: Aqua: Medium – For core and upper body exercises Black: Strong – Upper ",
      },
      {
        property: "og:price:amount",
        content: "35.00",
      },
      {
        property: "og:price:curency",
        content: "GBP",
      },
    ],
  },
  {
    path: "/products/hiit-band-in-black",
    title: "HIIT Band",
    description:
      "Fabric band for that added extra burn to fast-paced HIIT workouts.  Slim 5cm resistance band Rubber LEAN logo Stretchable fabric material Non-slip design Perfect for: HIIT workouts that require a wider movement range like Jump Squats, Jumping Jacks, Plank Jacks, Bicycles Bag: Every band comes in a grey cotton draw stri",
    extra: [
      {
        property: "og:type",
        content: "product.group",
      },
      {
        property: "og:description",
        content:
          "Fabric band for that added extra burn to fast-paced HIIT workouts.  Slim 5cm resistance band Rubber LEAN logo Stretchable fabric material Non-slip design Perfect for: HIIT workouts that require a wider movement range like Jump Squats, Jumping Jacks, Plank Jacks, Bicycles Bag: Every band comes in a grey cotton draw stri",
      },
      {
        property: "og:price:amount",
        content: "13.00",
      },
      {
        property: "og:price:curency",
        content: "GBP",
      },
    ],
  },
  {
    path: "/blogs/all-recipies",
    title: "Healthy Recipes",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/blogs/blog",
    title: "Blog",
    description:
      "If you want to build body confidence through living a healthy lifestyle, but don’t know where to start – you’ve come to the right place. Join the #LeanWithLilly family to take more steps towards feeling your most confident self, by living a healthier and happier lifestyle!",
  },
  {
    path: "/products/the-anti-inflammatory-cook-book/",
    title: "The Anti-Inflammatory & Immunity Boosting Cookbook",
    description:
      "From fighting the dreaded bloat and inflamed skin/acne, to healing your gut and injuries, decreasing inflammation and boosting your immune system are absolutely key.Whether you're after recipes to help you fight bloat, heal from injuries, calm inflamed skin or repair your gut, what you eat is absolutely key -- and infl",
  },
  {
    path: "/collections/frontpage/products/video-8-week-lean-transformation-guide",
    title: "VIDEO 8 Week LEAN Transformation Guide",
    description:
      "8 Week LEAN Transformation Guide eBook + Real Time Videos This 8 week training guide provides you with your own trainer, instructing you throughout Real Time Workouts every single day, for 8 weeks.Lilly, your trainer is with you every step of the way during your 8-week transformation. Providing daily motivation, encour",
  },
  {
    path: "/collections/frontpage/products/personal-8-week-lean-transformation-guide",
    title: "PERSONAL 8 Week LEAN Transformation Guide",
    description:
      "8 Week LEAN Transformation Guide eBook + Real Time Videos + Up close and personal with Lilly  We all have different fitness goals: physical and mental. This online coaching package is perfect for anyone who is looking for a closer relationship with their trainer, Lilly, throughout their personal fitness transformation ",
  },
  {
    path: "/collections/frontpage/products/essential-8-week-lean-transformation-guide",
    title: "ESSENTIAL 8 Week LEAN Transformation Guide",
    description:
      "8 Week LEAN Transformation Guide eBook This 8 week training eBook is designed to help you build a lean, toned physique using 4 styles of training: Fat Burn HIIT, Resistance Training, Pilates and Active Recovery. PLEASE NOTE, UNLIKE THE LEAN VIDEO GUIDE, THE LEAN ESSENTIAL GUIDE DOES NOT INCLUDE REAL TIME VIDEOS. THIS E",
  },
  {
    path: "/collections/frontpage/products/lean-beginner-guide-4-weeks-to-a-more-confident-you",
    title: "LEAN Beginner Guide: 4 Weeks To A More Confident You",
    description:
      "Our goal is to be the healthiest and happiest versions of ourselves. To feel confident, feel healthy and look great from the inside out. The issue is time, I don’t know about you, but I don’t have 2 hours spare every day to go to the gym. Even if I do manage to build up the motivation to drag myself to the gym, I have ",
  },
  {
    path: "/pages/workout-guides",
    title: "Free Workout Guides",
  },
  {
    path: "/join",
    title: "Join",
  },
  {
    path: "/app/login",
    title: "Login",
  },
  {
    path: "/subscription",
    title: "Subscribe",
  },
  {
    path: "/forgot-my-password",
    title: "Forgot Password",
  },
  {
    path: "/app/subscription",
    title: "Subscribe",
  },
  {
    path: "/reset-password",
    title: "Reset Password",
  },
  // {
  //   path: "/lean-app",
  //   title: "How to get the LEAN app?",
  //   description: `Famila it’s so easy to get access and download the LEAN App! We know you can’t wait to get your hands on LEAN with Lilly’s amazing new health and fitness app and it couldn’t be easier to sign up. For the best experience, we recommend you create an account with us first via our website and then download the app and login. Three simple steps:

  //   First create an account by registering with us here: Register for LEAN app
  //   Then select a subscription type and proceed to payment.
  //   Then head to the App Store or Google Play Store, download the LEAN App and log in using the same details you used on our website. It’s that simple!
  //   `,
  // },
  {
    path: "/home",
    title: "Lean With Lilly",
  },
  {
    path: "/policies/acceptable-use-policy",
    title: "Acceptable Use Policy",
  },
  {
    path: "/policies/website-terms-and-conditions",
    title: "Website T&Cs",
  },
  {
    path: "/student",
    title: "Student Discount",
  },
  {
    path: "/pages/social-media",
    title: "Social Media",
  },
  {
    path: "/hallolean",
    title: "HalloLEAN",
  },
];
