export const footerLinks = {
  information: {
    shippingInformation: "https://leanwithlilly.com/policies/shipping-policy",
    faq: "https://support.leanwithlilly.com/hc/en-us",
    refund: "https://leanwithlilly.com/policies/refund-policy",
  },

  legal: {
    terms: "https://leanwithlilly.com/policies/terms-of-service",
    privacyPolicy: "https://leanwithlilly.com/policies/privacy-policy",
    consumerGoods:"https://leanwithlilly.com/policies/terms-of-service", 
    acceptableUsePolicy:"https://leanwithlilly.com/pages/acceptable-use-policy",
    doNotSellPersonalInfo: "https://leanwithlilly.com/pages/ccpa-opt-out"
  },
};
