import React, { useState, useEffect } from "react";
import { Flex } from "../../elements/Flex";
import { Image } from "../../elements/Image";
// import Banner from "../../components/Banner";
import { BRAND } from "../../utils/assets";
import { Button } from "../../elements/Button";
// import { FaChevronDown, FaShoppingCart } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import MobileMenu from "../MobileMenu";
import { GrClose } from "react-icons/gr";
import { Box } from "../../elements/Box";
import { links } from "./links";
import { Link, navigate } from "gatsby";
import NavLink from "./NavLink";
import { FaUser } from "react-icons/fa";
// import CountrySelectModal from "../CountrySelectModal";
// import { Text } from "../../elements/Text";
import { useUser } from "../../hooks/useUser";
import css from "@styled-system/css";
// import { Span } from "../../elements/Span";
// import Dropdown from "./Dropdown";

const Navbar = ({ transparent }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [authUserDropdownOpen, setAuthUserDropdownOpen] = useState(false);
  const { activeSubscription, handleSubscriptionsCheck, authenticated, logout } = useUser();
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const pathname = typeof window !== "undefined" && window.location.pathname;
  // useEffect(() => setIsAuthenticated(authenticated), [authenticated]);

  /*const authUserLinks = [
    {
      name: "Log Out",
      onClick: () => {
        logout();
        navigate("/");
      },
    },
  ];*/

  useEffect(() => {
    if (isOpen) {
      document.body.style.scroll = "none";
    }
  }, []);

  useEffect(handleSubscriptionsCheck, []);
  // const [showModal, setShowModal] = useState(false);
  // const location = typeof window !== "undefined" && localStorage.getItem("storeLocation");

  // const store = !location ? "UK" : location !== "us" ? "UK" : "US";

  

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  /*
  const AuthUserDropDownWrapper = ({ children }) => (
    <Span
      minWidth="4rem"
      cursor="pointer"
      onClick={() => isAuthenticated && setAuthUserDropdownOpen(!authUserDropdownOpen)}
    >
      {isAuthenticated ? (
        <Flex position="relative">
          {children}
          <FaChevronDown />
          <Dropdown links={authUserLinks} isOpen={authUserDropdownOpen} close={() => setAuthUserDropdownOpen(false)} />
        </Flex>
      ) : (
        <Link to="/join">{children}</Link>
      )}
    </Span>
  );
  */

  return (
    <>
      {/* <CountrySelectModal location={location} isOpen={showModal} onClose={() => setShowModal(false)} /> */}
      {/* <Banner bannerText="BLACK FRIDAY SALE NOW UP TO 50% OFF" path="/shop/all" /> */}
      <nav>
        <Box
          display="grid"
          gridTemplateColumns={["0.5fr 2fr 3fr", "repeat(3, 1fr)", "1fr 5fr 1fr"]}
          bg={transparent ? [null, null, "transparent"] : "white"}
          position={transparent && [null, null, "absolute"]}
          zIndex={transparent && [null, null, 1000]}
          width={transparent && [null, null, "100%"]}
          px={3}
          py={[2, 2, 3]}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            display={["block", "block", "none"]}
            variant="icon"
            color="grey"
            aria-controls="MobileNav"
            aria-expanded="false"
            aria-label="Menu"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <GrClose size={20} aria-hidden="true" /> : <FiMenu size={25} aria-hidden="true" />}
          </Button>

          <Flex justifySelf={["center", "center", "flex-start"]}>
            <Link to="/">
              <Image
                src={BRAND.logo}
                width="115px"
                height="auto"
                alt={BRAND.name}
                css={css({
                  filter: transparent ? [null, null, "brightness(100)"] : null,
                })}
              />
            </Link>
          </Flex>

          <Flex display={["none", "none", "flex"]} justifySelf={"center"} flexDirection="row">
            {links.map((props, index) => (
              <NavLink transparent={transparent} color="white" key={index} {...props} />
            ))}
          </Flex>

          <Flex justifySelf="flex-end">
            {/* <Button
              color={transparent ? [null, null, "white"] : "black"}
              display={["none", "none", "block"]}
              variant="icon"
              mr={2}
              onClick={() => setShowModal(true)}
            >
              <Text underline>{store} Store</Text>
            </Button> */}

            {/* <Link to="/app/manage-subscription">
              <Button
                variant="icon"
                color={transparent ? [null, null, "white"] : "black"}
                mr="1.75rem"
                aria-label="Account"
              >
                <FaUser size={15} aria-hidden="true" />
              </Button>
            </Link> */}

            {/* <Link to="/cart">
              <Button variant="icon" color={transparent ? [null, null, "white"] : "black"} aria-label="Shopping Cart">
                <Flex
                  position="absolute"
                  borderRadius="50%"
                  width="22px"
                  height="22px"
                  color={transparent ? [null, null, "white"] : "black"}
                  justifyContent="center"
                  alignItems="center"
                  right="20px"
                >
                  {totalItems}
                </Flex>
                <FaShoppingCart size={15} aria-hidden="true" />
              </Button>
            </Link> */}
          </Flex>
        </Box>
        <MobileMenu isOpen={isOpen} />
      </nav>
    </>
  );
};

export default Navbar;
