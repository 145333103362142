import React, { useEffect, useState } from "react";
import { Flex } from "../../elements/Flex";
import { Image } from "../../elements/Image";
import Banner from "../../components/Banner";
import { BRAND } from "../../utils/assets";
import { Button } from "../../elements/Button";
import { FaChevronDown, FaShoppingCart, FaUser, FaChevronLeft } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import MobileMenu from "../MobileMenu";
import { GrClose } from "react-icons/gr";
import { Box } from "../../elements/Box";
import { links } from "./app-links";
import { Link } from "gatsby";
import NavLink from "./NavLink";
import Dropdown from "./Dropdown";
// import { useUser } from "../../hooks/useUser";
import { Span } from "../../elements/Span";
import { navigate } from "gatsby-link";
import logoWhiteWeb from "../../images/brand/LogoWhiteWeb.png";
import { useUser } from "../../hooks/useUser";
import useIsLoggedIn from "../../utils/auth/useIsLoggedIn";

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const [authUserDropdownOpen, setAuthUserDropdownOpen] = useState(false);
  const { activeSubscription, handleSubscriptionsCheck, logout } = useUser();
  const [authenticated] = useIsLoggedIn();
  const pathname = typeof window !== "undefined" && window.location.pathname;

  const authUserLinks = [
    {
      name: "Log Out",
      onClick: () => {
        logout();
        navigate("/");
      },
    },
  ];

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  // useEffect(handleSubscriptionsCheck, []);

  const AuthUserDropDownWrapper = ({ children, color }) => (
    <Span
      minWidth="4rem"
      cursor="pointer"
      onClick={() => authenticated && setAuthUserDropdownOpen(!authUserDropdownOpen)}
    >
      {authenticated ? (
        <Flex position="relative">
          {children}
          <FaChevronDown color={color} />
          <Dropdown links={authUserLinks} isOpen={authUserDropdownOpen} close={() => setAuthUserDropdownOpen(false)} />
        </Flex>
      ) : (
        <Link to="/join">{children}</Link>
      )}
    </Span>
  );

  return (
    <>
      <nav>
        <Box
          display="grid"
          gridTemplateColumns={["repeat(3, 1fr)", "repeat(3, 1fr)", "1fr 5fr 1fr"]}
          bg="teal"
          px={3}
          py={[2, 2, 3]}
          justifyContent="space-between"
          alignItems="center"
          borderBottom="solid 1px rgba(255, 255, 255, 0.4)"
        >
          <Button
            display={["block", "block", "none"]}
            variant="icon"
            color="grey"
            aria-controls="MobileNav"
            aria-expanded="false"
            aria-label="Menu"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <GrClose size={20} aria-hidden="true" color="white" />
            ) : (
              <FiMenu size={25} aria-hidden="true" color="white" />
            )}
          </Button>

          <Flex justifySelf={["center", "center", "flex-start"]} alignItems="center">
            <Link to="/" style={{ marginRight: "1rem" }}>
              <FaChevronLeft size={30} aria-hidden="true" color="white" />
            </Link>
          </Flex>

          <Flex display={["none", "none", "flex"]} justifySelf={"center"} flexDirection="row" alignItems="center">
            <Link to="/" style={{ marginRight: "1rem" }}>
              <Image src={BRAND.logoWhiteWeb} width="5rem" height="auto" alt={BRAND.name} />{" "}
            </Link>
            {links?.map((props, index) => (
              <NavLink
                key={activeSubscription + index}
                {...props}
                activeSubscription={activeSubscription}
                color="white"
              />
            ))}
          </Flex>

          <Flex justifySelf="flex-end">
            <AuthUserDropDownWrapper color="white">
              {authenticated}
              <FaUser size={15} aria-hidden="true" color="white" />
            </AuthUserDropDownWrapper>
          </Flex>
        </Box>
        <MobileMenu isOpen={isOpen} />
      </nav>
    </>
  );
};
