import styled from "styled-components";
import { border, color, space, typography } from "styled-system";

// Theme with font.primary/secondary etc.
export const ExternalLink = styled.a`
  text-decoration: ${(props) => props.textDecoration || "underline"};
  ${typography};
  ${space}
  ${border}
  ${color}
`;
