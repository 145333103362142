import React, { useEffect, useState } from "react";
import { Text } from "../../elements/Text";
import { Link } from "gatsby";
import { Span } from "../../elements/Span";
import { FaChevronDown } from "react-icons/fa";
import { Flex } from "../../elements/Flex";
import { aboutLinks, shopLinks } from "./links";
import { ExternalLink } from "../../elements/ExternalLink";
import Dropdown from "./Dropdown";
import { Box } from "../../elements/Box";
import { BsFillLockFill as LockedIcon, BsUnlockFill as UnlockedIcon } from "react-icons/bs";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const NavLink = ({ transparent, name, path, href, requiresSubscription, activeSubscription, color, scrollToID }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isShopLink = false;

  const pathname = typeof window !== "undefined" && window.location.pathname;

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  const containerStyle = requiresSubscription
    ? {
        backgroundColor: transparent ? null : "teal",
        borderRadius: "0.2rem",
        padding: "0.1rem 0",
      }
    : {};

  const LockIcon = activeSubscription ? UnlockedIcon : LockedIcon;

  const ScrollLinkHandler = ({ to, scrollToID, children, ...props }) => {
    const updatedTo = !!scrollToID ? `${to}#${scrollToID}` : to;
    return (
      <AnchorLink {...props} to={updatedTo}>
        {children}
      </AnchorLink>
    );
  };

  return (
    <Box {...containerStyle}>
      {href ? (
        <ExternalLink textDecoration="none" href={href} target="_blank" rel="noopener noreferrer">
          <Text mx={[1, 1, 2, 3]} variant={transparent ? "transparentNav" : "nav"}>
            {name}
          </Text>
        </ExternalLink>
      ) : !isShopLink ? (
        <ScrollLinkHandler to={path} scrollToID={scrollToID}>
          <Text
            mx={[1, 1, 2, 3]}
            variant={requiresSubscription ? "freemiumNav" : transparent ? "transparentNav" : "nav"}
          >
            {name}
            {requiresSubscription && (
              <Span>
                <LockIcon color="darkPink " size={16} style={{ marginLeft: "0.2rem", marginBottom: "-0.1rem" }} />
              </Span>
            )}
          </Text>
        </ScrollLinkHandler>
      ) : isShopLink ? (
        <Flex position="relative">
          <Text mx={[1, 1, 2, 3]} variant={transparent ? "transparentNav" : "nav"} onClick={() => setIsOpen(!isOpen)}>
            {name}{" "}
            <Span>
              <FaChevronDown />
            </Span>
          </Text>
          <Dropdown links={shopLinks} isOpen={isOpen} close={() => setIsOpen(false)} />
          {/**/}
        </Flex>
      ) : (
        <Flex position="relative">
          <Text mx={[1, 1, 2, 3]} variant={transparent ? "transparentNav" : "nav"} onClick={() => setIsOpen(!isOpen)}>
            {name}{" "}
            <Span>
              <FaChevronDown />
            </Span>
          </Text>
          <Dropdown links={aboutLinks} isOpen={isOpen} close={() => setIsOpen(false)} />
        </Flex>
      )}
    </Box>
  );
};

export default NavLink;
