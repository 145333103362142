import styled, { css } from "styled-components";
import { border, color, flexbox, layout, space, typography, variant } from "styled-system";

// Theme with font.primary/secondary etc.
export const Text = styled.p`
  display: block;
  ${typography};
  ${space}
  ${border}
  color: ${(props) => `${props.color} !important`};
  ${layout}
  font-family: ${(props) => props.fontFamily};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  text-transform: ${(props) => props.textTransform};
  ${flexbox}
  text-decoration: ${(props) => props.underline && "underline"};
  text-decoration: ${(props) => props.lineThrough && "line-through"};
  word-wrap: ${(props) => props.wordWrap};
  white-space: ${(props) => props.whiteSpace};

  ${({ truncate }) =>
    truncate &&
    css`
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  cursor: ${(props) => props.cursorPointer && "pointer"};

  ${variant({
    variants: {
      nav: {
        cursor: "pointer",
        position: "relative",
        letterSpacing: 1,
        color: "grey",
        fontSize: [11, 11, 11, 13],
        fontWeight: "600",
        textTransform: "uppercase",
        cursorPointer: true,
        "&:hover": {
          color: "peach",
        },
      },
      transparentNav: {
        cursor: "pointer",
        position: "relative",
        letterSpacing: 1,
        color: [null, null, "white"],
        fontSize: [11, 11, 11, 13],
        fontWeight: "600",
        textTransform: "uppercase",
        cursorPointer: true,
        "&:hover": {
          color: "peach",
        },
      },
      freemiumNav: {
        cursor: "pointer",
        position: "relative",
        letterSpacing: 1,
        color: "white",
        fontSize: [11, 11, 11, 13],
        fontWeight: "600",
        textTransform: "uppercase",
        cursorPointer: true,
        "&:hover": {
          color: "black",
        },
      },
    },
  })}
`;
