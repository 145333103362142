import { createGlobalStyle } from "styled-components";
import LemonMilk from "../fonts/LemonMilk.ttf";

export default createGlobalStyle`

    html, * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        line-height: 1.5;
       font-family: "Montserrat", sans-serif;
    }

    @font-face {font-family: "Lemon/Milk"; src: url("//db.onlinewebfonts.com/t/67137b5f6928edc941186baaab5a664b.eot"); src: url("//db.onlinewebfonts.com/t/67137b5f6928edc941186baaab5a664b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/67137b5f6928edc941186baaab5a664b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/67137b5f6928edc941186baaab5a664b.woff") format("woff"), url("//db.onlinewebfonts.com/t/67137b5f6928edc941186baaab5a664b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/67137b5f6928edc941186baaab5a664b.svg#Lemon/Milk") format("svg"); }

    h1 {
        font-family: "Lemon/Milk", sans-serif;
    }


    button {
      cursor: pointer;
    }

    a {
        text-decoration: none;
        color: grey;
        cursor: pointer;
    }

    a > svg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #countdown-timer {
        font-size: 2rem;        
    }

    @media screen and (min-width: 768px){
        #countdown-timer {
   
        font-size: 3.4rem
        
    }
    }
`;
