import { flattenDeep, startCase } from "lodash";
import toJSON from "../../../utils/toJSON";

export const REST_DAY_PREFIX = "REST_DAY";
export const isRestDay = (obj) => obj?.id?.indexOf(REST_DAY_PREFIX) > -1;

const transformWorkoutProductToClasses = (workoutProduct, metadata) => {
  const transformExerciseToClass = (
    {
      exercise_id: exerciseId,
      name: classTitle,
      meta_data,
      order: classOrder,
      session_exercise_id: id,
      thumbnail,
      time: durationSeconds,
      video: url,
      embed_url,
      sprout_id: sproutId,
    },
    extraData
  ) => {
    // If an exercise/class has been tagged with multiple workoutTypes then add the class to both sections
    return meta_data?.workoutType?.map((workoutType) => {
      return {
        courseTitle: metadata?.isCurrentGuide
          ? workoutProduct?.name
          : startCase(workoutType.replace("main", "Full Classes")),
        isCurrentGuide: metadata?.isCurrentGuide,
        guideProduct: workoutProduct,
        mainGroup: workoutType,
        classTitle,
        classOrder,
        exerciseId,
        sessionExerciseId: id,
        asset: {
          id,
          meta_data,
          thumbnail,
          url: embed_url || url,
          sproutId,
          durationSeconds,
        },
        ...extraData,
      };
    });
  };

  return flattenDeep(
    workoutProduct?.workouts?.map(
      ({ id: workoutId, name: subCategory, sessions, order: subCategoryOrder }, weekIndex) =>
        sessions?.map(
          (
            { id: sessionId, exercises, name: sessionName, estimated_time, meta_data: sessionMetaData, order },
            dayIndex
          ) => {
            return !!exercises?.length
              ? exercises?.map((exercise, dayExerciseIndex) => {
                  // Parse stringified JSON
                  const meta_data = toJSON(exercise.meta_data);

                  // Additional data from workout, session etc that is required to create a class object
                  const extraData = {
                    isCurrentGuide: metadata?.isCurrentGuide,
                    totalDayExercises: exercises?.length,
                    dayIndex,
                    sessionMetaData,
                    workoutId,
                    sessionId,
                    subCategory,
                    subCategoryOrder,
                    lockId: sessionId,
                    lockType: "guide",
                    guideDayPosition: weekIndex * 7 + dayIndex + 1,
                  };

                  return transformExerciseToClass(
                    {
                      ...exercise,
                      meta_data,
                    },
                    {
                      ...extraData,
                      dayExerciseIndex,
                      // Replaced by sessionId filtering
                      // ...(meta_data?.workoutType.indexOf('main') > -1 && {
                      //     accessoryClasses: flattenDeep(exercises
                      //     ?.filter((accessoryExercise) =>
                      //         toJSON(accessoryExercise?.meta_data)?.workoutType.indexOf('main') === -1)
                      //     ?.map(accessoryExercise => transformExerciseToClass({
                      //         ...accessoryExercise,
                      //         meta_data: toJSON(accessoryExercise?.meta_data),
                      //     }, extraData))),
                      // }),
                    }
                  );
                })
              : [
                  {
                    id: `${REST_DAY_PREFIX}_${sessionId}_${dayIndex}`,
                    totalDayExercises: 0,
                    dayIndex,
                    sessionMetaData,
                    sessionId,
                    subCategory,
                    subCategoryOrder,
                    isCurrentGuide: metadata?.isCurrentGuide,
                    guideProduct: workoutProduct,
                    classTitle: sessionName,
                    classOrder: 0,
                    guideDayPosition: weekIndex * 7 + dayIndex + 1,
                    // asset: {
                    //     id,
                    //     meta_data,
                    //     thumbnail,
                    //     url: embed_url || url,
                    //     sproutId,
                    //     durationSeconds,
                    // },
                  },
                ];
          }
        )
    )
  );
};

export default transformWorkoutProductToClasses;
