import styled, { css } from "styled-components";
import { border, color, layout, position, space } from "styled-system";

export const Image = styled.img`
  display: block;
  object-fit: ${(props) => props.objectFit || "contain"};
  box-shadow: ${(props) => props.boxShadow || ""};
  max-width: 100%;
  height: auto;
  ${layout};
  ${space};
  ${border};
  ${position};
  ${color}

  aspect-ratio: ${(props) => props.aspectRatio};

  ${({ filterBrightness }) =>
    filterBrightness &&
    css`
      filter: brightness(100);
    `}
`;
