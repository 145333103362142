import React, { createContext, useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby-link";
import { isEmpty as _isEmpty, isEqual } from "lodash";
import { cancelSubscription, getSubscription } from "../utils/stripe";
import { getRevenueCatUserSubscriptions } from "../utils/revenueCat";
import { useAuthManager, useIsLoggedIn } from "@genflow/web-auth";
import { useCompletedSessions } from "@genflow/web-workouts";
import useProfileUserData from "@genflow/web-auth/hooks/useProfileUserData";
import { CHECKOUT_URL } from "../utils/constants";

export const UserContext = createContext();

const DEFAULT_STATE = {
  isFetchingSubscriptions: true,
  activeSubscription: null,
  subscriptions: {},
  stripeSubscription: null,
  confirmCancelStripe: false,
  stripeSubscriptionCancelled: false,
  stripeSubscriptionCancelFailed: false,
  profile: null,
};

/*
 * This providers contents can be accessed by useUser() and aims to simplify restricted content & user management
 * Manages:
 * 1) User Authentication state
 * 2) User RevenueCat subscriptions history state
 * 3) Redirection within the initial funnel of /join (non registered) and /subscription on any page that utilises it
 * */

const DEBUG_LOGS = true;
const log = (...args) => DEBUG_LOGS && console.log(...args);
const UserProvider = ({ children }) => {
  const { getUser, authenticating, register, login, logout } = useAuthManager();
  const authenticated = useIsLoggedIn();
  console.log('UserProvider authenticated: ', authenticated)
  const [state, setState] = useState(DEFAULT_STATE);
  const { activeSubscription } = state;
  const [user, setUser] = useState(null);
  const updateState = (changes) => setState({ ...state, ...changes });

  const [isStudent, setIsStudent] = useState(false);

  // // IF user is logged in but has no profile, send to download page
  const { data: profileUserData, loading: profileUserDataLoading } = useProfileUserData();

  const hasNoProfile = useMemo(() => {
    return   (profileUserData === undefined || profileUserData?.data === undefined) && profileUserDataLoading === false;
  }, [profileUserDataLoading, profileUserData]);

  // useEffect(() => {
  //   // console.log('authenticated', authenticated);
  //   console.log('hasNoProfile', hasNoProfile, profileUserData, profileUserDataLoading);
  //   if (authenticated && hasNoProfile) {
  //     console.log('HASNOPROFILE');
  //     navigate(`/app/classes/download`);
  //   }
  // }, [authenticated, hasNoProfile]);

  const updateSubscriptionStatus = async () => {
        // Check subscriptions
        const user = await getUser();
        let userActiveSubscription;
        try {
          updateState({ isFetchingSubscriptions: true });
    
          const subscriptions = await getRevenueCatUserSubscriptions(user?.username);
          userActiveSubscription = (subscriptions || [])?.filter(({ is_active }) => is_active)?.[0];
    
          log(`Active Subscription:`, userActiveSubscription);
    
          let stripeSubscription = null;
    
          if (userActiveSubscription?.store === "web") {
            const getSubscriptionResponse = await getSubscription();
            stripeSubscription = getSubscriptionResponse?.data;
            stripeSubscription = _isEmpty(stripeSubscription) ? null : stripeSubscription;
          }
          
          const updates = {}
          if (!isEqual(state.activeSubscription, userActiveSubscription)) {
            updates.activeSubscription = userActiveSubscription;
          }
          if (!isEqual(state.subscriptions, subscriptions)) {
            updates.subscriptions = subscriptions;
          }
          if (!isEqual(state.stripeSubscription, stripeSubscription)) {
            updates.stripeSubscription = stripeSubscription;
          }
          if (!isEqual(state.cognitoUser, user?.attributes)) {
            updates.cognitoUser = user?.attributes;
          }
          console.log('updates', updates, _isEmpty(updates));
          updateState({
            isFetchingSubscriptions: false,
            ...updates
          });
        } catch (e) {
          log("Error parsing user subscriptions");
          log(e);
        }
  }
  // Subscription check
  const handleSubscriptionsCheck = async () => {
    console.log('handleSubscriptionsCheck');
    console.log('authenticated', authenticated);
    console.log('activeSubscription: ', activeSubscription);
    console.log('profileUserData: ', profileUserData)

    const isAppUrl = window.location.pathname.includes("/app");
    console.log('isAppUrl', isAppUrl);
    if (authenticated && !activeSubscription) {
      // navigate(CHECKOUT_URL);
    } else if (authenticated && activeSubscription && !isAppUrl) {
      navigate(`/app/classes`);
    }

  };

  // React.useEffect(updateSubscriptionStatus, [authenticated]);

  // When subscription state changes, navigate
  useEffect(() => {
    handleSubscriptionsCheck();
    // if (authenticated && !state.isFetchingSubscriptions) {
    //   console.log('checking sub status...', state);
    //   if (state?.activeSubscription) {
    //     console.log("TO CLASSES")
    //     navigate(`/app/classes`);
    //   } else {
    //     console.log("PAY TO PLAY")
    //     // navigate(`/app/subscription`);
    //   }
    // }
  }, [activeSubscription]);


  React.useEffect(() => {
    (async () => {
      if (!authenticating) {
        try {
          await updateSubscriptionStatus();
          const freshUser = await getUser();
          setUser(freshUser);
        } catch (e) {
          console.log("Error fetching user", e);
        }
      }
    })();
  }, [authenticating, authenticated]);

  log({
    ...state,
    user,
    authenticated,
    authenticating,
  });

  return (
    <UserContext.Provider
      value={{
        ...state,
        user,
        authenticated,
        authenticating,
        register,
        login,
        handleSubscriptionsCheck,
        logout,
        isStudent,
        setIsStudent,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
