import React from "react";
import { ExternalLink } from "../../elements/ExternalLink";
import { Flex } from "../../elements/Flex";
import { Image } from "../../elements/Image";
import Twitter from "../../images/icons/twitter@3x.png";
import YouTube from "../../images/icons/youtube@3x.png";
import Facebook from "../../images/icons/facebook@3x.png";
import Instagram from "../../images/icons/insta@3x.png";
import { maxWidth } from "styled-system";

const SocialButtons = ({ maxWidth = "41px", maxHeight = "37px" }) => {
  const socials = [
    {
      name: "YouTube",
      link: "https://www.youtube.com/channel/UCTsM1dSAiXqiV5oZjuNw_Bg",
      icon: YouTube,
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/lillysabri/?hl=en",
      icon: Instagram,
    },
    {
      name: "Facebook",
      link: "https://www.facebook.com/lilly.sabri",
      icon: Facebook,
    },
    {
      name: "Twitter",
      link: "https://twitter.com/lillysabri?lang=en",
      icon: Twitter,
    },
  ];
  return (
    <Flex>
      {socials.map(({ link, icon }, index, array) => (
        <ExternalLink key={index} target="_blank" rel="noopener noreferrer" href={link}>
          <Image
            src={icon}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            height="100%"
            mr={index !== array.length - 1 ? 3 : 0}
          />
        </ExternalLink>
      ))}
    </Flex>
  );
};

export default SocialButtons;
