import { parseInt } from 'lodash';
import {
    DEMO_CLASSES_IDS,
    DEMO_CONTENT_IDS,
    DEMO_GUIDE_SESSION_IDS,
    DEMO_MEAL_IDS,
} from './DEMO_IDS';

export default {
    lock: {
        meal: (val) => !DEMO_MEAL_IDS.includes(parseInt(val)),
        mealPlan: (val) => val > 3,
        content: (val) => !DEMO_CONTENT_IDS.includes(parseInt(val)),
        guide: (val) => !DEMO_GUIDE_SESSION_IDS.includes(parseInt(val)),
        session: (val) => val > 3,
        class: (val) => !DEMO_CLASSES_IDS.includes(parseInt(val)),
        challenge: (val) => !!val,
        product: (val) => !val,
    },
};
