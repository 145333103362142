import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import AppNavBar from "../components/Navbar/AppNavBar";

const Layout = ({ location, children }) => {
  const showAppNavBar = typeof window !== "undefined" && window?.location?.pathname.startsWith("/app/classes");

  const transparent =
    location.pathname.includes("/lean-app") ||
    location.pathname === "/shop" ||
    location.pathname === "/shop/";

  console.log(location);

  return (
    <main style={{ position: "relative", overflowX: "hidden" }}>
      {showAppNavBar ? <AppNavBar /> : <Navbar transparent={transparent} />}
      {children}
      <Footer />
    </main>
  );
};

export default Layout;
