export const DEMO_MEAL_IDS = [
    613659,
    613661,
    613687,
    613689,
    614389,
    614486,
    614203,
    614363,
    614515,
    614338,
    614390,
    614487,
    614204,
    614364,
    613911,
    613913,
    614391,
    614488,
    614205,
    614365,
    614490,
    614500,
    614392,
    614489,
];

// Includes free content i.e. How to use LEAN bands
export const DEMO_CONTENT_IDS = [
    597140, 597002, 596993, 597026, 596974, 596991, 596992, 596996, 596997, 596998, 596994,
    596995,
];

// export const DEMO_DIET = 'Pescatarian';
// export const DEMO_WORKOUT_PRODUCT_ID = 3098;
// export const DEMO_WORKOUT_SESSION_ID = 50296;
// export const DEMO_WORKOUT_SESSION_POSITION = 1;

// export const DEMO_HABIT_TEXT = 'Please subscribe to add more habits and journal entries';

// export const FREE_COURSE_PRODUCT_ID = 3222;


// export const DEMO_MEALPLAN_MEAL_IDS = [
//     614363, 614216, 613687, 614392,
// ];

// export const DEMO_CLASSES_IDS = [
//     // 50189, 50106, 50101, 50412, Featured classes
//     2170888, 2170889, 2170890, 2170891, 2170892,
// ]; // Note: these are a mixture of sessions and exercises, hence seperate list

export const DEMO_GUIDE_SESSION_IDS = [
    50410, 50411, 50412, 50459, 50460, 50462, 50487, 50488, 50489, 50515, 50516, 50517, 50184,
    50185, 50186, 50198, 50199, 50200, 50226, 50228, 50227, 50254, 50256, 50255, 50100, 50101,
    50102, 50296, 50297, 50299, 50324, 50327, 50326, 50352, 50353, 50354,
];

export default {
    DEMO_MEAL_IDS,
    // DEMO_DIET,
    // DEMO_WORKOUT_PRODUCT_ID,
    // DEMO_WORKOUT_SESSION_ID,
    DEMO_CONTENT_IDS,
    // DEMO_WORKOUT_SESSION_POSITION,
    // DEMO_HABIT_TEXT,
    // FREE_COURSE_PRODUCT_ID,
    // DEMO_MEALPLAN_MEAL_IDS,
    // DEMO_CLASSES_IDS,
    DEMO_GUIDE_SESSION_IDS,
};
