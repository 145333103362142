import React from "react";
import { ExternalLink } from "../../elements/ExternalLink";
import { Flex } from "../../elements/Flex";
import { Image } from "../../elements/Image";
import AppleStore from "../../images/icons/app-store.png";
import GooglePlay from "../../images/icons/GooglePlay.png";
import WatchOnLEANWeb from "../../images/icons/watch-on-lean-web-teal.png";
import Link from "gatsby-link";

const DownloadButtons = ({ noLink, maxHeight = "60px", maxWidth = "150px" }) => {
  return (
    <Flex flexWrap="wrap" justifyContent="flex-start">
      {noLink ? (
        <>
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/lean-with-lilly/id1563460661"
          >
            <Image
              src={AppleStore}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              height="100%"
              mr={2}
            />
          </ExternalLink>
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.leanwithlilly.app"
          >
            <Image
              src={GooglePlay}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              height="100%"
              mr={2}
            />
          </ExternalLink>
          <Link to="/app/classes">
            <Image
              src={WatchOnLEANWeb}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              height="100%"
            />
          </Link>
        </>
      ) : (
        <>
          {" "}
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/lean-with-lilly/id1563460661"
          >
            <Image
              src={AppleStore}
              maxWidth="152px"
              maxHeight={maxHeight}
              height="100%"
            />
          </ExternalLink>
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.leanwithlilly.app"
          >
            <Image
              src={GooglePlay}
              maxWidth="173px"
              maxHeight={maxHeight}
              height="100%"
            />
          </ExternalLink>
        </>
      )}
    </Flex>
  );
};

export default DownloadButtons;
