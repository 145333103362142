export const GUIDES_CONTAINER_SCROLL_BINDING = "GUIDES_CONTAINER_SCROLL_BINDING";
export const YOUR_GUIDE_SCROLL_BINDING = "YOUR_GUIDE_SCROLL_BINDING";
export const ALL_CLASSES_SCROLL_BINDING = "ALL_CLASSES_SCROLL_BINDING";

export const APP_SCROLL_BINDINGS = {
  [GUIDES_CONTAINER_SCROLL_BINDING]: "guides",
  [YOUR_GUIDE_SCROLL_BINDING]: "current-guide",
  [ALL_CLASSES_SCROLL_BINDING]: "all-classes",
};

export const links = [
  {
    name: "Guides",
    path: "/app/classes",
    scrollToID: APP_SCROLL_BINDINGS[GUIDES_CONTAINER_SCROLL_BINDING],
  },
  {
    name: "Your Guide",
    path: "/app/classes",
    scrollToID: APP_SCROLL_BINDINGS[YOUR_GUIDE_SCROLL_BINDING],
  },
  {
    name: "All Classes",
    path: "/app/classes",
    scrollToID: APP_SCROLL_BINDINGS[ALL_CLASSES_SCROLL_BINDING],
  },
];
