import styled, { css, keyframes } from "styled-components";
import {
  space,
  layout,
  color,
  flexbox,
  background,
  boxShadow,
  border,
  position,
  typography,
  grid,
} from "styled-system";

import { pulse } from "react-animations";

const pulseAnimation = keyframes`${pulse}`;
export const Box = styled.div`
  cursor: ${(props) => props.cursor || ""};
  overflow: ${(props) => props.overflow || ""};
  transform: ${(props) => props.transform || ""};
  box-shadow: ${(props) => props.boxShadow || ""};
  border: ${(props) => props.border || ""};
  z-index: ${(props) => props.zIndex || ""};
  animation: ${(props) =>
    !!props.pulse
      ? css`
          ${pulseAnimation} .5s
        `
      : ""};

  &:before {
    content: ${(props) => props?.before || ""};
  }

  &:hover {
    transform: ${(props) => props?.hoverTransform || ""};
    transition-duration: 0.25s;
    z-index: 999;
  }
  ${space};
  ${layout};
  ${color};
  ${flexbox};
  ${background};
  ${border};
  ${position};
  ${typography};
  ${grid};
`;
