import axios from "axios";

import { has as _has, filter as _filter, map as _map } from "lodash";

const baseUrl = "https://api.revenuecat.com/v1/subscribers/";
const revenueCatToken = process.env.REVENUECAT_TOKEN;

const getRevenueCatSubscriptions = (userName) =>
  axios.get(baseUrl + encodeURIComponent(userName), {
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${revenueCatToken}`,
      "Content-Type": "application/json",
    },
    data: {},
  });

const transformSubscriberData = (subscriber) => {
  if (!subscriber || typeof subscriber !== "object" || !subscriber.entitlements) {
    throw "There is a problem getting the subscriber data";
  }

  if (!_has(subscriber, "entitlements.full_access")) {
    throw "Non active subscription";
  }

  const subscriptions = _map(subscriber.subscriptions, (subscription) => {
    const store = subscription.store === "stripe" ? "web" : subscription.store;

    return {
      is_active: new Date(subscription.expires_date) > Date.now(),
      expires_date: new Date(subscription.expires_date),
      original_purchase_date: new Date(subscription.original_purchase_date),
      period_type: subscription.period_type,
      purchase_date: new Date(subscription.purchase_date),
      unsubscribe_detected_at: subscription.unsubscribe_detected_at
        ? new Date(subscription.unsubscribe_detected_at)
        : null,
      store: store,
    };
  });

  return subscriptions;
};

export const getRevenueCatUserSubscriptions = async (userName) => {
  if (!userName || typeof userName !== "string") {
    throw "You must provide a username of type `string`!";
  }
  let subscriptions = [];

  try {
    const result = await getRevenueCatSubscriptions(userName);
    subscriptions = transformSubscriberData(result.data.subscriber);
  } catch (e) {
    console.log(e);
    return false;
  }

  return subscriptions;
};

export const isUserSubscribed = async (userName) => {
  if (!userName || typeof userName !== "string") {
    return false;
  }
  let isSubscribed = null;
  // let isSubscribed = sessionStorage.getItem(`subscriptions_${userName}`);
  // if(isSubscribed){
  //     return JSON.parse(isSubscribed);
  // }
  try {
    const result = await getRevenueCatSubscriptions(userName);
    isSubscribed = transformSubscriberData(result.data.subscriber);
  } catch (e) {
    console.log(e);
    return false;
  }
  // sessionStorage.setItem(`subscriptions_${userName}`, JSON.stringify(isSubscribed));
  return isSubscribed;
};
