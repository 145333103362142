import styled, { css } from "styled-components";
import { border, color, flexbox, layout, space, typography } from "styled-system";

// Theme with font.primary/secondary etc.
export const Span = styled.span`
  ${typography};
  ${space}
  ${border}
  ${color}
  text-decoration: ${(props) => props.underline && "underline"};
  cursor: ${(props) => props.pointer && "unset"};
  ${flexbox}
  ${layout}

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};

  ${(props) =>
    props.cursorPointer &&
    css`
      cursor: pointer;

      &:hover {
        color: "#f3766e";
      }
    `};
`;
