const theme = {
  colors: {
    teal: "#2db3b6",
    darkTeal: "#2db3b6",
    white: "#ffffff",
    pink: "#fbcbc6",
    darkPink: "#ca4c6d",
    grey: "#1e252b",
    green: "#33ca55",
    peach: "#f3766e",
    lightGrey: "#f5f5f5",
    red: "#CC0000",
    midGrey: "#3f4443",
    lilac: "#879cdb",
    offWhite: "#eff6f6",
  },
};

export default theme;
