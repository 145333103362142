const CHECKOUT_URL = 'https://app.leanwithlilly.com'

const vendorUUID = "a9ee763f-e6db-43cb-81a4-26e4bd0a573e";
const PRICES = {
  yearlyPrice: "79.99",
  yearlyPricePerMonth: "6.67",
  yearlySaving: "33",
  quarterlyPrice: "24.49",
  quarterlySaving: "15",
  monthlyPrice: "9.99",
};

const ACTIVE_SALE = false;
const SALE_KEY = 'renewannual';
const SALE_PRODUCT = '30offannually';
const SALE_LABEL = 'EXCLUSIVE OFFER';
const PRODUCT_SALE = false;
const STUDENT_DISCOUNT = 25;

const SALE_PRICES = {
  yearlyPrice: "54.99",
  yearlyPricePerMonth: "4.58",
  yearlySaving: "30",
  // quarterlyPrice: "18.99",
  // quarterlySaving: "20",
  // monthlyPrice: "7.99",
  // monthlySaving: "20",
};

const MONTHLY = ACTIVE_SALE ? "20offmonthly" : "monthly";
const QUARTERLY = ACTIVE_SALE ? "20offquarterly" : "quarterly";
const YEARLY = ACTIVE_SALE ? "30offannually" : "annually";

// const MONTHLY = "bf2021monthly";
// const QUARTERLY = "bf2021quarterly";

const CATALOG_ORDER = [
  "Roll It Up Bundle",
  "Full Mat Bundle",
  "Midnight Palm Yoga Mat",
  "Ocean Dream Yoga Mat",
  "Aqua Carry Strap",
  "Lavender Carry Strap",
];

module.exports = {
  CHECKOUT_URL,
  MONTHLY,
  QUARTERLY,
  YEARLY,
  vendorUUID,
  PRICES,
  SALE_PRICES,
  ACTIVE_SALE,
  PRODUCT_SALE,
  STUDENT_DISCOUNT,
  CATALOG_ORDER,
  SALE_KEY,
  SALE_PRODUCT,
  SALE_LABEL,
};
