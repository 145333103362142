import { Auth, Hub } from 'aws-amplify';
import { useState, useEffect } from 'react';

export default (debug) => {
    const [state, setState] = useState({
        loggedIn: null,
        user: null,
    });
    useEffect(() => {
        (async () => {
            try {
                const newUser = await Auth.currentUserInfo();
                if (debug) {
                    console.log('useIsLoggedIn initial user response: ', newUser);
                    if (newUser) {
                        const sessionCredentials = await Auth.currentSession();
                        const cognitoToken = sessionCredentials.getIdToken().getJwtToken();
                        console.log('useLoggedIn: Cognito Token: ', cognitoToken);
                    }
                }
                setState({
                    loggedIn: !!newUser,
                    user: newUser,
                });
            } catch (error) {
                if (error.message.includes('this.currentObservable.query.resetQueryStoreErrors')) {
                    console.error('Resolutions have not been set correctly. Please run npm install again to force resolutions. If the issue persists, please check your package.json contains "npx npm-force-resolutions" as a preinstall script. If not, please check with the Genflow team for guidance on how to do this.');
                } else {
                    console.error('Error in @genflow/auth isLoggedIn: ', error);
                }
            }
        })();

        Hub.listen('auth', async (data) => {
            const { event } = data.payload;
            if (debug) {
                console.log('New auth event found in useIsLoggedIn: ', event, data);
            }
            switch (event) {
            case 'signIn': {
                const newUser = await Auth.currentUserInfo();
                setState({
                    ...state,
                    loggedIn: true,
                    user: newUser,
                });
                break;
            }
            case 'signOut':
            case 'signIn_failure':
            case 'signUp_failure':
                setState({
                    ...state,
                    loggedIn: false,
                });
                break;
            default:
                console.warn('Unhandled Auth Hub event found in useIsLoggedIn: ', event);
                break;
            }
        });
    }, []);

    return [state?.loggedIn, state?.user];
};
