import { flattenDeep, fromPairs, toPairs, get } from "lodash";

// Options:
// edits - Object - Keys here will replace each item of the course response, so you can manipulate the output

export default (course, options = {}) => {
  const getEdits = (asset, index) =>
    fromPairs(
      toPairs(get(options, "edits", {})).map((pair) => [
        pair[0],
        typeof pair[1] === "function" ? pair[1](asset, index) : pair[1],
      ])
    );

  return flattenDeep(
    course?.sections?.map(({ name: subCategory, order: subCategoryOrder, lessons }) =>
      lessons?.map((lesson, index) => {
        const { name: classTitle, order: classOrder, assets } = lesson;
        return assets?.map(() => {
          let videoAssets = assets?.filter(({ asset_type }) => asset_type?.name === "Video");

          return videoAssets?.map((videoAsset) => {
            const assetMetaData = JSON.parse(videoAsset?.meta_data || "{}");

            return {
              courseTitle: course?.name,
              mainGroup: course?.name,
              subCategory,
              subCategoryOrder,
              classTitle,
              classOrder,
              productID: course?.productID,
              lockId: videoAsset?.id,
              lockType: "content",
              asset: {
                type: videoAsset?.asset_type,
                description: videoAsset?.description,
                id: videoAsset?.id,
                meta_data: assetMetaData,
                durationSeconds: assetMetaData?.duration,
                thumbnail: videoAsset?.thumbnail,
                title: videoAsset?.title,
                url: videoAsset?.embedUrl || videoAsset?.url,
                sproutId: videoAsset?.sproutId,
              },
              ...getEdits(lesson, index),
            };
          });
        });
      })
    ) || []
  );
};
