import React from "react";
import theme from "./src/theme";
import GlobalStyle from "./src/theme/GlobalStyle";
import { ThemeProvider } from "styled-components";
import Layout from "./src/layout";
// import CartProvider from "./src/contexts/CartContext";
import { metaData } from "./src/utils/metaData";
import Helmet from "react-helmet";
import UserProvider from "./src/contexts/UserContext";
import ContentManagerProvider from "./src/contexts/ContentManager/ContentManagerContext";
import { CoreProvider } from "@genflow/core";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import config from "./src/config";
import AuthProvider from "@genflow/web-auth/contexts/AuthContext";
import getApolloClient from "./src/utils/getApolloClient";
import "./src/init";
import { ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const apolloClient = getApolloClient();

export const wrapRootElement = ({ element }) => {
  return (
    <CoreProvider config={config}>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
            <AuthProvider>
              <UserProvider>
                <ContentManagerProvider>{element}</ContentManagerProvider>
              </UserProvider>
            </AuthProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </CoreProvider>
  );
};

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  const path = props.location.pathname;
  const url = props.location.href;

  const meta = metaData.filter((item) => item.path === path || `${item.path}/` === path)[0];
  let { title, description, type = "website", extra = [] } = meta || {};

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout {...props}>
        <Helmet
          title={title ? `${title} - LEAN With Lilly` : "LEAN With Lilly"}
          meta={[
            {
              name: "description",
              content: description,
            },
            {
              property: "og:site_name",
              content: "LEAN With Lilly",
            },
            {
              property: "og:url",
              content: url,
            },
            {
              property: "og:title",
              content: title,
            },
            {
              property: "og:type",
              content: type,
            },
            {
              property: "og:description",
              content: description,
            },
            {
              name: "twitter:site",
              content: "@lillysabri?lang=en",
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
            {
              name: "twitter:title",
              content: title,
            },
            {
              name: "twitter:description",
              content: description,
            },
            ...extra,
          ]}
        />
        {element}
      </Layout>
    </ThemeProvider>
  );
};

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
