import AppSyncConfig from "../init/appsync-configure";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import { Auth } from "aws-amplify";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

const ssrMode = typeof window === "undefined";
if (ssrMode) {
  global.fetch = require("isomorphic-fetch");
}

const { url: uri } = AppSyncConfig;

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const cognitoToken = session?.getIdToken()?.getJwtToken();

  // console.log('cognitoToken', cognitoToken);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      cognitoToken,
    },
  };
});

const link = ApolloLink.from([
  createAuthLink(AppSyncConfig),
  authLink,
  createSubscriptionHandshakeLink(AppSyncConfig.url, new HttpLink({ uri })),
]);
const client = new ApolloClient({
  ssrMode,
  link,
  cache: new InMemoryCache(),
});

export default () => client;
