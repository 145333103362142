import Amplify from "aws-amplify";
import env from "../env";
import config from "../config";

const { identityPoolId, region, userPoolId, userPoolWebClientId, mandatorySignIn } = config[env].cognito;

Amplify.configure({
  Auth: {
    identityPoolId,
    region,
    userPoolId,
    userPoolWebClientId,
    mandatorySignIn: mandatorySignIn !== "false",
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
});
