import React from "react";
import { Text } from "../elements/Text";
import { Span } from "../elements/Span";
import { ExternalLink } from "../elements/ExternalLink";
import logo from "../images/brand/Logo.png";
import logoWhite from "../images/brand/LogoWhite.png";
import logoWhiteWeb from "../images/brand/LogoWhiteWeb.png";
import iPhoneCutout from "../images/iphones/iPhoneCutout.webp";
import dumbbell from "../images/icons/dumbbell.png";
import star from "../images/icons/star.png";
import mango from "../images/icons/mango.png";
import leanGuides from "../images/products/leanGuides.webp";
import resistanceBands from "../images/products/resistanceBands.webp";
import cookbooks from "../images/products/cookbooks.jpeg";
import quotes from "../images/icons/quotes.svg";
import lilyBannerLondon from "../images/banners/lilyBannerLondon.jpg";
import LEANWebBanner from "../images/banners/lean-web-banner.png";
import whatWeDo1 from "../images/iphones/whatWeDo1.webp";
import whatWeDo2 from "../images/iphones/whatWeDo2.webp";
import whatWeDo3 from "../images/iphones/whatWeDo3.webp";
import blog1 from "../images/food/blog1.jpeg";
import blog2 from "../images/food/blog2.jpeg";
import blog3 from "../images/food/blog3.jpeg";
import Instagram from "../images/icons/Instagram.png";
import about1 from "../images/about/about1.png";
import about2 from "../images/about/about2.png";
import resistanceBandsBanner from "../images/banners/resistanceBands.png";
import leanGuidesBanner from "../images/banners/leanGuidesBanner.jpeg";
import LeanBeginnerGuide from "../images/products/LeanBeginnerGuide.jpeg";
import PersonalGuide from "../images/products/personalGuide.jpeg";
import LeanVideoGuide from "../images/products/leanVideo.jpeg";
import EssentialGuide from "../images/products/essentialGuide.jpeg";
import Lilly from "../images/lilly/Lilly.jpg";
import transformation1 from "../images/transformations/1.jpg";
import transformation2 from "../images/transformations/2.jpg";
import transformation3 from "../images/transformations/3.jpg";
import transformation4 from "../images/transformations/4.jpg";
import transformation5 from "../images/transformations/5.jpg";
import transformation6 from "../images/transformations/6.jpg";
import transformation7 from "../images/transformations/7.jpg";
import transformation8 from "../images/transformations/8.jpg";
import transformation9 from "../images/transformations/9.jpg";
import transformation10 from "../images/transformations/10.jpg";
import transformation11 from "../images/transformations/11.jpg";
import transformation12 from "../images/transformations/12.jpg";
import transformation13 from "../images/transformations/13.jpg";
import transformation14 from "../images/transformations/14.jpg";
import transformation15 from "../images/transformations/15.jpg";
import transformation16 from "../images/transformations/16.jpg";
import transformation17 from "../images/transformations/17.jpg";
import transformation18 from "../images/transformations/18.jpg";
import transformation19 from "../images/transformations/19.jpg";
import transformation20 from "../images/transformations/20.jpg";
import transformation21 from "../images/transformations/21.jpg";
import transformation22 from "../images/transformations/22.jpg";
import Lilly5 from "../images/lilly/Lilly2.jpg";
import AppBanner from "../images/app/Banner.jpg";
import Lilly1 from "../images/app/Lilly1.jpg";
import Lilly2 from "../images/app/Lilly2.jpg";
import Lilly3 from "../images/app/Lilly3.jpg";
import Lilly4 from "../images/app/Lilly4.jpg";
import SupplementsBanner from "../images/supplements/banner.jpg";
import SupplementsBannerDesktop from "../images/supplements/SupplementBannerDesktop.jpg";
import NourishAndGlow from "../images/banners/suppscomingsoon.jpg";
import suppsShopnow from "../images/banners/shopnowSupps.png";

export const BRAND = {
  name: "Lean With Lilly",
  logo,
  logoWhite,
  logoWhiteWeb,
};

export const TRANSFORMATIONS = [
  transformation1,
  transformation2,
  transformation3,
  transformation4,
  transformation5,
  transformation6,
  transformation7,
  transformation8,
  transformation9,
  transformation10,
  transformation11,
  transformation12,
  transformation13,
  transformation14,
  transformation15,
  transformation16,
  transformation17,
  transformation18,
  transformation19,
  transformation20,
  transformation21,
  transformation22,
];

export const IMAGES = {
  suppsShopnow,
  NourishAndGlow,
  SupplementsBanner,
  SupplementsBannerDesktop,
  Lilly5,
  Lilly,
  AppBanner,
  Lilly1,
  Lilly2,
  Lilly3,
  Lilly4,
  LeanVideoGuide,
  EssentialGuide,
  PersonalGuide,
  quotes,
  iPhoneCutout,
  leanGuides,
  resistanceBands,
  cookbooks,
  lilyBannerLondon,
  LEANWebBanner,
  whatWeDo1,
  whatWeDo2,
  whatWeDo3,
  blog1,
  blog2,
  blog3,
  Instagram,
  about1,
  about2,
  resistanceBandsBanner,
  leanGuidesBanner,
  LeanBeginnerGuide,
};

export const ICONS = [
  {
    icon: dumbbell,
    text: "Home Workout Programs",
    footerText: "Real Time Workout Guides",
    alt: "dumbbell",
  },
  {
    icon: mango,
    text: "Healthy Recipes & Snacks",
    footerText: "Healthy Recipes",
    alt: "mango",
  },
  {
    icon: star,
    text: "#LeanWithLilly Family",
    footerText: "#LeanWithLilly Family",
    alt: "star",
  },
];

// Homepage product thumbnail data
export const thumbnails = [
  {
    name: "LEAN Guides",
    src: IMAGES.leanGuides,
    path: "/collections/frontpage",
    alt: "Lean Guides",
  },
  {
    name: "Cookbooks",
    src: IMAGES.cookbooks,
    path: "/products/the-anti-inflammatory-cook-book",
    alt: "Cookbooks",
  },
  {
    name: "Resistance Bands",
    src: IMAGES.resistanceBands,
    path: "/collections/resistance-bands",
    alt: "Resistance Bands",
  },
];

// Testimonial slider data
export const testimonials = [
  {
    name: "@graceisobel18",
    content: `“Thanks to Lilly I feel and look so much better in myself, she has helped me through so much and I am beyond grateful”
  `,
  },
  {
    name: "@suhaanihardikar",
    content: `“I’m the best version of myself and I’m so happy and proud of myself for coming this far by myself, with of course, Lilly Sabri ‘s support🤍🤍 I still can’t believe how in love with my body I am now and I just want to remind everyone that you should do it for YOU.”
  `,
  },
  {
    name: "@eatwithalicerose",
    content: `“Lilly has truly changed my life, especially during lockdown. Doing her workouts every morning have kept me sane by giving me a routine and for the most part they are the highlight of my day.”
    ”
  `,
  },
  {
    name: "@megs.fitnessdiary",
    content: `“I used to hate any sort of cardio/HIIT workouts before I started Lilly’s workouts.
    I’ve always struggled with my mental health, but now I’ve found a way to properly deal with it through exercise. Thank you Lilly Sabri, you have changed my life ❤️”    
     
  `,
  },
  {
    name: "@dwttyduck",
    content: `“I've still got my ways to go yet, but I'm determined and have Lilly here to help me reach my goals 💪🏻thank you Lilly!!! Love all you beautiful, strong ladies ❤️💪🏻❤️”
    ”    
  `,
  },
  {
    name: "@riya_paul_10",
    content: `“I should be thanking Lilly for how drastically she changed my life. My only birthday wish would be (though I'm not supposed to mention it loud) to meet Lilly one day and thank her for how she helped this teen to love her own reflection and manage school and workout like a pro :)” 
  `,
  },
  {
    name: "@Drea_monteros",
    content: `“Can I just say that my face says it all? 🙈
    Life without Lilly’s workouts vs life with Lilly’s workouts 💪🏻😄
    I’ve never felt this confident, even before having kids my body never looked this way.
    Thank you thank youuuu Lilly Sabri!!!”
    
  `,
  },
  {
    name: "@bb_8dre",
    content: `“I’m 20 pounds down! It’s not a lot but I’m damn proud of the progress I’ve made. I’ve never been one to be physically fit and into working out but with the help of @kyl0rene, all of Lilly Sabri workouts, and some friends that kept me motivated. I kept persisting at a slow pace and now I can physically see results. Can’t wait to see where this health journey goes 😊”
    #lwltransformations      
  `,
  },
  {
    name: "@jerni.gains",
    content: `“I am a momma of 3 kids aged 9, 5 and 1yr. I found Lilly’s workouts on YouTube and I’m so incredibly grateful for the FUN, QUICK, and effective workouts! When I began I never imagined that I would ever get the results I have today.Thank you Lilly for being around at a time when I felt so lost and hopeless, you didn’t know it but you helped push me through for myself and my family. I’m so happy to finally have the confidence I always desired, the strength I never knew I could achieve, and the ability to know that even in the hard times, I can do this. 💪 💗”
  `,
  },
  {
    name: "@caitlin.eslinger",
    content: `Hey everyone! 👋
    I started following Lilly’s workouts in January when my husband and I booked an all inclusive vacation for October. I was NOT excited about how I was looking and certainly didn’t want to put on a bikini! For anyone out there feeling like it’s not working, it is!! I promise! Stick with it! 💪💪💪
       
  `,
  },
  {
    name: "@dikshyaxoxo",
    content: `“I'm really confident and it's helping me improve both my physical and mental health . It's completely your choice to treat your body the way you want . Everyone's perfect and you should love yourself. I really want to thank Lilly Sabri for amazing workout programs and I was able to buy lean bands as well. Thank you.”
  `,
  },
  {
    name: "@samiwelland",
    content: `Hey everyone! 👋
    “Lilly has changed my life!! In the past year I have felt healthier, and happier! Before I found Lilly I was suffering from depression, had no energy, and ate fairly unhealthy. I’m getting closer and closer to my goal and am loving every second! Thank you Lilly Sabri !”
  `,
  },
  {
    name: "@vidaacristina",
    content: `“Before I start to work out, I was searching YouTube workouts, and I found you.... Girlllllll everything is burning 🔥 after 10 min 😂 I never looking so good,and I just want to say a big thank you. You are such a bigg example for everyone... and bcz today is my 30'th birthday 🎂 ... I catch courage to show you this... I bcz of you 🤗”
  `,
  },
  {
    name: "@teganparkfit",
    content: `Hey everyone! 👋
    “" I am honestly so shocked that I’ve managed to do all of this just from Lilly’s YouTube videos and wanted to thank you all so much, seeing everyone post their workout pictures and progress pictures has been amazing!”

  `,
  },
];

export const blogCards = [
  {
    src: IMAGES.blog1,
    title: "Post workout pick me up salad",
    path: "/blogs/all-recipies/post-workout-pick-me-up-salad/",
    tags: [
      {
        name: "Breakfast",
        path: "/blogs/all-recipies",
      },
    ],
  },
  {
    src: IMAGES.blog2,
    title: "White chocolate & raspberry protein chia treat",
    path: "/blogs/all-recipies/white-chocolate-raspberry-protein-chia-treat/",
    tags: [
      {
        name: "Breakfast",
        path: "/blogs/all-recipies",
      },
      {
        name: "Healthy Snacks",
        path: "/blogs/all-recipies",
      },
    ],
  },
  {
    src: IMAGES.blog3,
    title: "Fruity Chia Breakfast Bowl",
    path: "/blogs/all-recipies/fruity-chia-breakfast-bowl/",
    tags: [
      {
        name: "Breakfast",
        path: "/blogs/all-recipies",
      },
    ],
  },
];

export const faqsData = [
  {
    section: "Orders and Payments",
    questions: [
      {
        title: "What types of payments are accepted?",
        content: (
          <Text>
            Credit and Debit Cards (Visa, Mastercard, Amex, Maestro), PayPal, Apple Pay, Google Pay and Shop Pay.
          </Text>
        ),
      },
      {
        title: "What happens if I want to change or cancel my order once purchased?",
        content: (
          <>
            <Text mb={3}>
              Dispatch takes place very quickly once orders have been received. If you want to change or cancel your
              order, please email the LEAN Customer Service team. Please note: entering the correct postal address is
              your responsibility. Orders will be dispatched to the address provided
            </Text>

            <Text>
              If you wish to make an amendment to your order once processed, please act quickly. Dispatch takes place
              very quickly and once your order has departed the distribution centre, we{" "}
              <Span fontWeight="600">cannot</Span> make amendments.
            </Text>
          </>
        ),
      },
      {
        title: "Do bundle discounts apply when you are purchasing more than one bundle?",
        content: (
          <Text>
            In order to remain fair to all customers, only one discount is applicable upon checkout. If you are
            purchasing more than one bundle, the discount will apply for the first bundle only.
          </Text>
        ),
      },
    ],
  },
  {
    section: "Shipping",
    questions: [
      {
        title: "How do I know when my order is dispatched",
        content: (
          <>
            <Text>
              Once your order has been dispatched, you will receive a confirmation email. For orders using a tracked
              shipping / courier service, the tracking number will be included in the confirmation of dispatch email.
            </Text>
            <Text mb={3}>
              If you opted for a shipping service without tracking, we do not have any access to information relating to
              delivery. This is the difference between the tracked and untracked postal options.
            </Text>

            <Text>
              If you prefer to know the exact status of your delivery, we recommend you select the tracked shipping /
              courier options.
            </Text>
          </>
        ),
      },
      {
        title: "Do I need to pay customs/import charges?",
        content: (
          <>
            <Text mb={3}>
              Orders placed outside the United Kingdom may encounter additional customs/import charges. These charges
              are not included in the shipping fee, and are the customer's responsibility to pay these. Should you
              encounter this situation, please contact the shipping provider directly.
            </Text>

            <Text fontWeight="600">EU Customers</Text>
            <Text>
              If you are looking to purchase our products and live in the EU, please note that since January 1st 2021
              you may incur additional customs duties and local VAT once the product has arrived in your country of
              residence. At this stage we cannot provide you with an estimate of these charges as it varies from country
              to country and will be based on the weight, price and dimensions of your order. We will continually
              monitor the situation and hope to provide you with better estimates down the line. We feel it is important
              you are aware of this before purchasing when outside of the UK.
            </Text>
          </>
        ),
      },
      {
        title: "Do bundle discounts apply when you are purchasing more than one bundle?",
        content: (
          <Text>
            In order to remain fair to all customers, only one discount is applicable upon checkout. If you are
            purchasing more than one bundle, the discount will apply for the first bundle only.
          </Text>
        ),
      },
      {
        title: "Why is my order taking longer than expected to arrive?",
        content: (
          <>
            <Text mb={3}>
              Once the item has departed our distribution centre, we do not have access to information relating to your
              delivery.
            </Text>
            <Text mb={3}>
              If you selected a tracked delivery option, you will be able to access information regarding your delivery.
              At LEAN, we do not have this information. Please contact the shipping service / courier service directly
              using the tracking number provided in your order confirmation email.
            </Text>

            <Text>
              Royal Mail:{" "}
              <ExternalLink
                href="https://personal.help.royalmail.com/app/contact"
                aria-describedby="a11y-external-message"
              >
                https://personal.help.royalmail.com/app/contact
              </ExternalLink>
            </Text>
            <Text>
              DPD:{" "}
              <ExternalLink
                href="https://www.dpd.co.uk/content/how-can-we-help/index.jsp"
                aria-describedby="a11y-external-message"
              >
                https://www.dpd.co.uk/content/how-can-we-help/index.jsp
              </ExternalLink>
            </Text>
            <Text>
              DHL:{" "}
              <ExternalLink
                href="https://www.dhl.com/gb-en/home/contact-us.html"
                aria-describedby="a11y-external-message"
              >
                https://www.dhl.com/gb-en/home/contact-us.html
              </ExternalLink>
            </Text>
            <Text>
              Aramex:{" "}
              <ExternalLink
                href="https://www.aramex.com/us/en/support/customer-support"
                aria-describedby="a11y-external-message"
              >
                https://www.aramex.com/us/en/support/customer-support
              </ExternalLink>
            </Text>
            <Text>
              Emirates Post:{" "}
              <ExternalLink
                href="https://emiratespost.ae/Portal/Home?locale=en-us"
                aria-describedby="a11y-external-message"
              >
                https://emiratespost.ae/Portal/Home?locale=en-us
              </ExternalLink>
            </Text>
          </>
        ),
      },
      {
        title: "My tracking number is not working. What do I do?",
        content: (
          <>
            <Text>
              If you wish to be able to track the location of your order, we recommend you select a tracked shipping /
              courier service. When making your purchase, you can select the service best for you.
            </Text>
            <Text>
              If you encounter issues relating to your tracking number, please contact the designated postal provider /
              shipping courier directly.
            </Text>
          </>
        ),
      },
      {
        title: "What do I do if I entered the wrong shipping address?",
        content: (
          <>
            <Text mb={3}>
              Before placing an order, please ensure that you have entered the correct shipping address and that your
              address is able to receive parcels [You can visit shipping courier’s website if you are unsure].
            </Text>
            <Text mb={3}>
              The shipping address you provide at the point of purchase is also summarised to you in your order
              confirmation.
            </Text>

            <Text mb={3}>
              Your items will be dispatched to the information you provide. Therefore, if you discover an error, please
              notify us immediately at contact@leanwithlilly.com.
            </Text>

            <Text mb={3}>
              Dispatch from our distribution centre takes place very quickly and therefore, if we are notified after
              dispatch, there are 2 options:
            </Text>

            <Text>
              1. If you selected tracked shipping, you can contact the designated postal provider / courier to notify
              them of the change in address. If you did not select tracked shipping, this is not possible.
            </Text>
            <Text>
              2. If the items are returned to our distribution centre in their original packaging, we will issue a
              refund or re-dispatch the items.
            </Text>
            <Text>
              Please note: if you provide an incorrect postal address and do not pay for a tracked postal / courier
              service, a refund will not be automatically issued. If the parcel is returned to our distribution centre,
              we will be able to issue a refund or re-dispatch the items. If the items are not returned, a refund will
              not be issued and the items will not be re-dispatched.
            </Text>
          </>
        ),
      },
      {
        title: "My order has been Returned to Seller. What do I do?",
        content: (
          <>
            <Text mb={3}>
              Some orders may be Returned to Seller by the shipping courier. This could be due to a number of reasons,
              the most common reasons being; incorrect delivery address, incomplete delivery address, or multiple failed
              delivery attempts. In this case, customers are offered 2 options:
            </Text>
            <Text>Issued a full refund once the parcel arrives back at our distribution center.</Text>
            <Text>
              Order is re-dispatched in 2 – 4 working days once the parcel arrives back at our distribution centre.
              Customers will be asked before dispatch to re–confirm their shipping address and a possible addition of a
              phone number so the shipping courier can contact the customer directly of any further difficulties with
              the shipment.
            </Text>
            <Text>
              The length of time it takes for a parcel to arrive may vary. For orders coming from within the UK can take
              up to 5 working days while international orders may take around 10 – 20 working days. It should be noted
              that this should only be relied upon as a guide only as it may arrive earlier/later than mentioned.
            </Text>
          </>
        ),
      },
      {
        title: "I chose a tracked shipping method and it says my tracking number is invalid?",
        content: (
          <>
            <Text>
              Unfortunately, the assistance of LEAN is limited as we have no control over the package tracking number
              once the order is handed over to the shipping courier. We strongly advised that you contact the designated
              shipping courier and request assistance directly from the source.
            </Text>
          </>
        ),
      },
    ],
  },
  {
    section: "Guides and Cookbook",
    questions: [
      {
        title: "How do I access my eBook / eGuide?",
        content: (
          <>
            <Text mb={3}>
              As stated in the product descriptions, all guides and the cookbook are downloadable digital products. You
              will not receive a physical product when purchasing these items. To access these purchases, you will
              receive an email containing the download links.
            </Text>
            <Text mb={3}>Please note: we will use the email you provided when making your purchase.</Text>
            <Text>
              If you do not receive the email containing the download links within 1 – 2 working days of making your
              purchase, please email contact@leanwithlily.com. Please check your junk/spam folders before contacting
              LEAN.
            </Text>
          </>
        ),
      },
      {
        title: "How do I access the videos on the eGuide?",
        content: (
          <Text>
            To access the videos, click on the red and white ‘play button’ icon that will redirect you to the videos. We
            recommend accessing the videos using a good internet connection.
          </Text>
        ),
      },
      {
        title: "I couldn't download my guide after 3 attempts and now my link has expired. What do I do now?",
        content: (
          <Text>
            Please email contact@leanwithlily.com with your order confirmation number and await further instructions.
          </Text>
        ),
      },
      {
        title: "How do I access the free workout guides?",
        content: (
          <Text>
            To access our free workout guides, please subscribe to the website’s mailing list. You will then be emailed
            the access link to the free workout guides.
          </Text>
        ),
      },
      {
        title: "I am not receiving the weekly workout guides anymore, how can I fix this?",
        content: (
          <Text>
            This can be fixed by unsubscribing to the newsletter, then re-subscribing. The new email you’re sent when
            you re-subscribe will contain the link to download the free guides. To unsubscribe, you need to search for
            an old email from Lilly. At the bottom of that email will be a prompt to unsubscribe. After clicking this,
            head to our website and re-subscribe.
          </Text>
        ),
      },
    ],
  },
  {
    section: "Resistance Bands",
    questions: [
      {
        title: "Which LEAN band bundle would you recommend for a beginner?",
        content: (
          <Text>
            Lilly recommends the Adjustable Resistance band [available in Aqua or Black] and the Strong HIIT band in
            Black.
          </Text>
        ),
      },
      {
        title: "What is the difference between all the LEAN bands",
        content: (
          <>
            <Text mb={3}>
              <Span fontWeight="600">Adjustable Resistance Bands:</Span> This band is for resistance and weight
              training, focusing on slower and more controlled exercises. The Adjustable Resistance band is LEAN’s most
              versatile band as it combines 3 bands into 1. By adjusting the buckle, the band accommodates for Medium,
              Strong and X – Strong resistance levels. The tighter the band is, the more resistance you experience and
              increases the difficulty of the exercise.
            </Text>

            <Text mb={3}>
              <Span fontWeight="600">X – Strong HIIT Band in Purple Rose:</Span> This is LEAN’s strongest HIIT band.
              Slightly shorter in length than the HIIT Band in Black but with more resistance, increasing the
              difficulty. This band is ideal for HIIT Training. The shape of the band provides intensity and makes your
              HIIT and cardio workout harder.
            </Text>

            <Text mb={3}>
              <Span fontWeight="600">Strong HIIT Band in Black:</Span> This band also works for HIIT Training. Lilly
              recommends this band when starting out and then progressing onto Purple Rose X – Strong band.
            </Text>

            <Text>
              <Span fontWeight="600">LEAN Long bands set:</Span> The brand new Long LEAN Bands comes in a set of 3 to
              accommodate different styles of workouts and different areas of the body. These bands can replace weights.
            </Text>
            <Text>Different Strengths</Text>
            <Text>
              <Span fontWeight="600">- Aqua:</Span> The brand new Long LEAN Bands comes in a set of 3 to accommodate
              different styles of workouts and different areas of the body. These bands can replace weights.
            </Text>
            <Text>
              <Span fontWeight="600">- Black:</Span> Strong – Upper body and some leg exercises
            </Text>
            <Text>
              <Span fontWeight="600">- Purple Rose:</Span> X Strong – Legs, glutes and other lower body exercises
            </Text>
          </>
        ),
      },
      {
        title: "Do bundle discounts apply when you are purchasing more than one bundle?",
        content: (
          <Text>
            In order to remain fair to all customers, only one discount is applicable upon checkout. If you are
            purchasing more than one bundle, the discount will apply for the first bundle only.
          </Text>
        ),
      },
      {
        title: "Who should purchase the X Strong HIIT Band in Purple Rose?",
        content: (
          <Text>
            The X - Strong HIIT band in Purple Rose is ideal for people who classify themselves at an intermediate level
            and anyone that wants a challenge and wants to feel the burn.
          </Text>
        ),
      },
      {
        title:
          "What is the difference between the Adjustable Resistance Band in Aqua and Adjustable Resistance Band in Black?",
        content: (
          <Text>
            The only difference between the two bands is the colour. Everything else, including the strength of the
            band, is the same.
          </Text>
        ),
      },
      {
        title: "Are you able to wash the bands?",
        content: (
          <Text>
            LEAN Bands can be washed, but avoid the washing machine. LEAN bands are handwash only. Soak the bands in
            water with a small amount of washing detergent. Leave to air dry. Do not put in the tumble dryer.
          </Text>
        ),
      },
      {
        title: "Can you use the Bands in the gym?",
        content: <Text>LEAN Bands are highly versatile and can be used during home workouts and in the gym.</Text>,
      },
    ],
  },
  {
    section: "Refunds and Returns",
    questions: [
      {
        title: "Can I return my order and get a refund?",
        content: (
          <>
            <Text mb={3}>Downloadable software items are non-refundable.</Text>
            <Text>
              Should you wish to return a physical purchase, please email contact@leanwithlilly for further
              instructions. We strongly advise that you read the refund policy before contacting us. For sanitary
              reasons, we can only accept returns of products in it's original packaging condition, with the exceptions
              of defective or incorrect items. If you’re eligible for a return and refund, you will receive a refund
              once the order is received at our distribution centre.
            </Text>
          </>
        ),
      },
      {
        title: "What do I do if I receive a defective or faulty product?",
        content: (
          <Text>
            Please email contact@leanwithlily.com and include photos of the defective product received plus your
            confirmation order number. Our customer service team will respond within 2-3 working days.
          </Text>
        ),
      },
      {
        title: "What do I do if I ordered 2 different products, but received 2 of the same product?",
        content: (
          <Text>
            Please email contact@leanwithlily.com and include photos of the purchased items plus your confirmation order
            number. You will receive further shipping instructions to send back the duplicate in exchange for the
            correct item purchased.
          </Text>
        ),
      },
    ],
  },
];

export const reviewCards = [
  {
    content: `"These bands are AMAZING, you'll get a new workout, I used to use plastic ones, they left me with a rash, pinched my skin and never seemed to give me good resistance then I got the LEAN bands and OMG the burn, theyr great quality amazing price I'm one happy customer 😊`,
    name: "Maysie Bye",
  },
  {
    content: `"The band is fantastic and really targets the muscle groups unlike every other band I've owned. Every other band I've bought plastic and fabric slip over time , I've had my band since the first release and it's never slipped or stretched. The adjustable band is also great instead of having loads of different bands at different weights"`,
    name: "Kayleigh Wild",
  },
  {
    content: `"By far the best bands I have ever used! The adjustable band is so genius that you can just adjust to whatever resistance you need and you will for sure feel it in your butt in the best way possible. I absolutely love them ♥️♥️"`,
    name: "Nicole Vena",
  },
];
