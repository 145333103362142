import { Link } from "gatsby";
import React from "react";
import { Flex } from "../../elements/Flex";
import { Text } from "../../elements/Text";
import useOnClickOutside from "react-onclickoutside";
import { Box } from "../../elements/Box";

const Dropdown = ({ links, isOpen, close }) => {
  // console.log(links);

  return (
    <Flex
      display={isOpen ? "flex" : "none"}
      mt={2}
      width="350px"
      zIndex="10"
      top="20px"
      flexDirection="column"
      position="absolute"
      bg="white"
      p={3}
    >
      {links.map(({ name, path, onClick, state }, index) =>
        name === "Back" ? null : (
          <Link to={path} key={index} onClick={onClick} state={{ filter: state || "" }}>
            <Box onClick={onClick}>
              <Text variant="nav">{name}</Text>
            </Box>
          </Link>
        )
      )}
    </Flex>
  );
};

export default Dropdown;
